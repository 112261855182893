<template>
  <v-container fluid fill-height class="primary">
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 md4 lg3>
        <v-card class="rounded-lg" flat>
          <v-toolbar dense flat>
            <v-spacer></v-spacer>
            <v-toolbar-title v-text="'Login'"></v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <v-container class="pt-0">
              <v-text-field
                v-model="email"
                label="Email"
                prepend-inner-icon="mdi-account"
                clearable
                autofocus
                @keyup.enter="SignIn"
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Password"
                prepend-inner-icon="mdi-lock"
                clearable
                @keyup.enter="SignIn"
                :type="passwordShow ? 'text' : 'password'"
              >
                <template v-slot:append>
                  <v-btn icon tabindex="-1">
                    <v-icon
                      v-if="passwordShow"
                      @click="passwordShow = false"
                      v-text="'mdi-eye'"
                      tabindex="-1"
                    ></v-icon>
                    <v-icon
                      v-if="!passwordShow"
                      @click="passwordShow = true"
                      v-text="'mdi-eye-off'"
                      tabindex="-1"
                    ></v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <div class="g-signin2"></div>

              <v-btn
                id="loginBtn"
                class="mt-3"
                block
                dark
                color="primary"
                @click="SignIn"
                v-text="'Login'"
              ></v-btn>
              <v-btn id="loginBtn" class="mt-3" block @click="SignInWithGoogle">
                <v-img
                  class="mx-2"
                  max-width="20"
                  max-height="20"
                  contain
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                ></v-img>
                Login with Google
              </v-btn>
              <div class="mt-3 text-center">
                Don't have an account?
                <router-link to="/sign-up">Create Account</router-link>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import router from "../plugins/router";

export default {
  name: "SignInVue",

  data: () => ({
    email: null,
    password: null,
    passwordShow: false,
  }),

  methods: {
    SignIn: function () {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() =>
          //userCredential
          {
            // Signed in
            // const user = userCredential.user;
            router.replace("/");
          }
        )
        .catch((error) => {
          // const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage);
        });
    },
    SignInWithGoogle: function () {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();

      signInWithPopup(auth, provider)
        .then(() =>
          // result
          {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // const credential = GoogleAuthProvider.credentialFromResult(result);
            // const token = credential.accessToken;
            // The signed-in user info.
            // const user = result.user;
            // IdP data available using getAdditionalUserInfo(result)
            router.replace("/");
          }
        )
        .catch((error) => {
          // Handle Errors here.
          // const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          // const email = error.customData.email;
          // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
          console.log(errorMessage);
        });
    },
  },
};
</script>
