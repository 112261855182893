<template>
  <v-container class="fill-height">
    <v-row class="fill-height">
      <v-col
        cols="12"
        :md="timerList.length == 1 ? 12 : 6"
        v-for="(timer, index) in timerList"
        :key="index"
      >
        <v-card class="fill-height" outlined>
          <v-card-title class="pb-0">
            <v-spacer></v-spacer>
            <v-menu
              ref="menu"
              v-model="timer.titleMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="display-1 font-weight-bold"
                  v-text="
                    timer.title != null ? timer.title : 'Click to change title'
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                </span>
              </template>
              <v-card>
                <v-text-field class="mx-3" v-model="timer.title" autofocus>
                  <template v-slot:append-outer>
                    <v-btn
                      dense
                      color="primary"
                      v-text="'OK'"
                      @click="timer.titleMenu = false"
                    ></v-btn>
                  </template>
                </v-text-field>
              </v-card>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn
              v-show="timerList.length > 1"
              color="red"
              icon
              small
              @click="deleteTimer(index)"
            >
              <v-icon v-text="'mdi-minus-circle'"></v-icon>
            </v-btn>
          </v-card-title>
          <v-dialog v-model="timer.timePickerDialog" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-card-text class="py-0">
                <v-text-field
                  v-model="timer.time"
                  label="Deadline"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </v-card-text>
            </template>
            <v-time-picker v-model="timer.time" format="24hr" full-width>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="timer.timePickerDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="timer.timePickerDialog = false"
              >
                OK
              </v-btn>
            </v-time-picker>
          </v-dialog>
          <flip-countdown
            v-show="timer.countdownShow"
            :deadline="timer.deadline"
            :showDays="false"
            :countdownSize="countdownSlider + 'px'"
            :labelSize="countdownSlider / 5 + 'px'"
            @timeElapsed="timeElapsedHandler(timer)"
          ></flip-countdown>
          <div class="d-flex justify-center pa-0 ma-0">
            <v-card
              v-show="timer.timeoutShow"
              class="mx-10 mt-3 overflow-auto"
              :class="countdownSlider <= 70 ? 'rounded-lg' : 'rounded-xl'"
              color="#222"
              dark
              :height="countdownSlider * 1.5"
              :width="countdownSlider * 6.4"
            >
              <v-card-title class="fill-height">
                <v-spacer></v-spacer>
                <v-img
                  alt="Bridge Pocket Logo"
                  class="shrink"
                  contain
                  :src="require('../assets/logo-512px.png')"
                  transition="scale-transition"
                  :width="countdownSlider"
                />
                <div
                  class="font-weight-bold"
                  :class="now % 2 == 0 ? 'yellow--text' : 'red--text'"
                  :style="'font-size:' + countdownSlider + 'px'"
                >
                  &nbsp;Time Out
                </div>
                <!-- <div
                  class="font-weight-bold"
                  :class="now % 2 == 0 ? 'yellow--text' : 'red--text'"
                v-text="'Out'"
                :style="'font-size:' + countdownSlider + 'px'"
              ></div> -->
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12"> </v-col>
    </v-row>
    <v-slider
      class="fill-height"
      v-model="countdownSlider"
      min="40"
      max="200"
      vertical
    ></v-slider>
    <v-btn color="primary" fab dark fixed bottom right @click="addTimer">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
import moment from "moment";

const fmt = "YYYY-MM-DD HH:mm:ss";

export default {
  name: "FlipCountdownVue",

  components: { FlipCountdown },

  data: () => ({
    countdownSlider: 130,
    timerList: [
      {
        title: null,
        titleMenu: false,
        time: null,
        timePickerDialog: false,
        deadline: moment().format(fmt),
        countdownShow: true,
        timeoutShow: false,
      },
    ],
    interval: null,
    now: Math.trunc(new Date().getTime() / 1000),
  }),

  created() {
    this.interval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 500);
    }, 500);
  },

  watch: {
    timerList: {
      handler: function (value) {
        value.forEach((timer) => {
          if (!timer.time) return;
          const hour = parseInt(timer.time.split(":")[0]);
          const minute = parseInt(timer.time.split(":")[1]);
          const deadlineMoment = moment()
            .hours(hour)
            .minutes(minute)
            .seconds(0);
          timer.deadline = deadlineMoment.format(fmt);
          if (moment().isBefore(deadlineMoment)) {
            timer.countdownShow = true;
            timer.timeoutShow = false;
          }
        });
      },
      deep: true,
    },
  },

  methods: {
    addTimer: function () {
      if (this.timerList.length == 4) return;
      this.timerList.push({
        title: null,
        titleMenu: false,
        time: null,
        timePickerDialog: false,
        deadline: moment().format(fmt),
        countdownShow: true,
        timeoutShow: false,
      });
      this.refreshCountdownSlider();
    },
    deleteTimer: function (index) {
      if (this.timerList.length == 1) return;
      this.timerList.splice(index, 1);
      this.refreshCountdownSlider();
    },
    refreshCountdownSlider: function () {
      switch (this.timerList.length) {
        case 1:
          this.countdownSlider = 130;
          break;
        default:
          this.countdownSlider = 70;
      }
    },
    timeElapsedHandler: function (timer) {
      timer.timeoutShow = true;
      timer.countdownShow = false;
    },
  },
};
</script>
