<template>
  <v-container>
    <v-card class="rounded-lg">
      <v-timeline align-top dense>
        <v-timeline-item hide-dot>
          <v-card-title
            class="pa-0"
            v-text="tournament ? tournament.name + ' - Schedule' : ''"
          >
          </v-card-title>
        </v-timeline-item>
        <div v-for="(schedules, i) in scheduleList" :key="i">
          <v-timeline-item hide-dot>
            <strong class="secondary--text" v-text="schedules[0].date"></strong>
          </v-timeline-item>
          <v-timeline-item
            v-for="schedule in schedules"
            :key="schedule.id"
            color="primary"
            small
          >
            <v-row>
              <v-col cols="3">
                <strong v-text="schedule.start + ' - ' + schedule.end"></strong>
              </v-col>
              <v-col>
                <strong v-text="schedule.title"></strong>
                <div class="text-caption" v-text="schedule.description"></div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </div>
      </v-timeline>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import { db } from "@/App.vue";
import { ref, onValue } from "firebase/database";

export default {
  name: "TournamentScheduleVue",

  data: () => ({
    tournament: null,
    scheduleList: [],
    dateFormat: "dddd, MMM DD, YYYY",
  }),

  created() {
    //Load Tournament
    const tournamentRef = ref(db, "tournaments/" + this.$route.params.id);
    onValue(tournamentRef, (snapshot) => {
      this.tournament = snapshot.val();
    });

    //Load Schedules
    const scheduleRef = ref(db, "tournamentSchedule/" + this.$route.params.id);
    onValue(scheduleRef, (snapshot) => {
      this.scheduleList = [];
      snapshot.forEach((childSnapshot) => {
        const data = childSnapshot.val();
        data.id = childSnapshot.key;
        const splittedDate = data.date.split("/");
        data.date = moment()
          .year(parseInt(splittedDate[0]))
          .month(parseInt(splittedDate[1]) - 1)
          .date(parseInt(splittedDate[2]))
          .format(this.dateFormat);

        let isPushed = false;
        this.scheduleList.forEach((schedules) => {
          if (schedules[0].date == data.date) {
            schedules.push(data);
            isPushed = true;
          }
        });
        if (!isPushed) {
          const schedules = [data];
          this.scheduleList.push(schedules);
        }
      });
    });
  },
};
</script>
