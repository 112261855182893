<template>
  <v-container>
    <v-card v-show="!$route.params.matchId" class="rounded-lg">
      <v-tabs
        v-model="currentRound"
        background-color="primary"
        dark
        centered
        center-active
      >
        <v-tab v-for="round in maxRound" :key="round">
          Round {{ round }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentRound" touchless>
        <v-tab-item v-for="round in maxRound" :key="round">
          <v-container>
            <v-card flat>
              <v-card-title>
                {{ tournament ? tournament.name : "" }} - Round {{ round }}
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  filled
                  rounded
                  dense
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="getHeader(currentRound)"
                :items="teamMatchList[currentRound]"
                :search="search"
                :options.sync="options"
                :loading="loading"
                :mobile-breakpoint="0"
                @click:row="tableRowClicked"
              >
              </v-data-table>
            </v-card>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-slide-y-transition v-show="$route.params.matchId" mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import router from "../plugins/router";
import { db } from "@/App.vue";
import { ref, onValue } from "firebase/database";

export default {
  name: "TournamentTeamMatchListVue",

  data: () => ({
    tournament: null,
    maxRound: 1,
    currentRound: 0,
    search: "",
    headerFull: [
      { text: "Table", value: "table", align: "end" },
      { text: "Home Team", value: "ht" },
      { text: "Visiting Team", value: "vt" },
      { text: "HT IMP", value: "htIMP" },
      { text: "VT IMP", value: "vtIMP" },
      { text: "HT VP", value: "htVP" },
      { text: "VT VP", value: "vtVP" },
    ],
    headerTeam: [
      { text: "Table", value: "table", align: "end" },
      { text: "Home Team", value: "ht" },
      { text: "Visiting Team", value: "vt" },
    ],
    teamMatchList: [],
    options: { itemsPerPage: -1 },
    loading: true,
  }),

  created() {
    //Load Tournament
    const tournamentRef = ref(db, "tournaments/" + this.$route.params.id);
    onValue(tournamentRef, (snapshot) => {
      const tournament = snapshot.val();
      this.tournament = tournament;
      this.maxRound = tournament.maxRound ? parseInt(tournament.maxRound) : 1;
      this.currentRound = tournament.currentRound
        ? parseInt(tournament.currentRound) - 1
        : 0;
      this.getData();
    });
  },

  methods: {
    tableRowClicked: function (item) {
      if (item.htIMP || item.vtIMP || item.htVP || item.vtVP) {
        router.push({
          name: "Team Match Detail",
          params: {
            matchId: item.id,
            round: "Round " + (this.currentRound + 1),
          },
        });
      }
    },
    getHeader: function (currentRound) {
      const teamMatches = this.teamMatchList[currentRound];
      let isScoreFilled = false;
      teamMatches.some((teamMatch) => {
        isScoreFilled =
          teamMatch.htId != "bye" &&
          teamMatch.vtId != "bye" &&
          (teamMatch.htIMP ||
            teamMatch.vtIMP ||
            teamMatch.htVP ||
            teamMatch.vtVP);
        return isScoreFilled;
      });
      if (
        teamMatches &&
        teamMatches.length > 0 &&
        isScoreFilled &&
        (this.tournament.showCurrRoundImpVp == undefined ||
          this.tournament.showCurrRoundImpVp)
      ) {
        return this.headerFull;
      } else {
        return this.headerTeam;
      }
    },
    getData: function () {
      this.loading = true;
      //Load Matchs
      this.teamMatchList = [];
      for (let i = 0; i < this.maxRound; i++) {
        const matches = [];
        this.teamMatchList.push(matches);
        const matchRef = ref(
          db,
          "tournamentTMs/" + this.$route.params.id + "/Round " + (i + 1)
        );

        onValue(matchRef, (snapshot) => {
          matches.splice(0, matches.length);
          snapshot.forEach((childSnapshot) => {
            const match = childSnapshot.val();
            match.id = childSnapshot.key;
            match.ht = "";
            match.vt = "";
            if (match.htId == "bye") {
              match.ht = "Bye";
              match.htIMP = "0";
              match.vtIMP = "0";
              match.htVP = "0";
            }
            if (match.vtId == "bye") {
              match.vt = "Bye";
              match.htIMP = "0";
              match.vtIMP = "0";
              match.vtVP = "0";
            }

            matches.push(match);

            const htRef = ref(
              db,
              "teams/" + this.$route.params.id + "/" + match.htId
            );
            onValue(htRef, (snapshot) => {
              const team = snapshot.val();
              if (team && team.teamName) match.ht = team.teamName;
            });

            const vtRef = ref(
              db,
              "teams/" + this.$route.params.id + "/" + match.vtId
            );
            onValue(vtRef, (snapshot) => {
              const team = snapshot.val();
              if (team && team.teamName) match.vt = team.teamName;
            });
          });

          matches.sort(function (a, b) {
            if (!a.table || !b.table) return 0;
            let x = a.table;
            let y = b.table;
            if (x < y) return -1;
            if (x > y) return 1;
            return 0;
          });
        });
      }
      this.loading = false;
    },
  },
};
</script>
