import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#099183",
        secondary: "#087267",
        accent: "#099183",
      },
    },
  },
  breakpoint: {
    mobileBreakpoint: "sm", // This is equivalent to a value of 960
  },
});
