<template>
  <div class="fill-height">
    <v-navigation-drawer v-model="navDrawerShow" app clipped>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6" v-text="tourName">
          </v-list-item-title>
          <v-list-item-subtitle v-text="category"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item-group v-model="navDrawerSelectedItem" color="primary">
          <v-list-item
            v-for="item in navDrawerList"
            :key="item.title"
            link
            :to="'/tournament/' + $route.params.id + '/' + item.path"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-container class="fill-height align-start">
      <v-row class="fill-height">
        <v-col cols="auto" class="pb-0" v-show="!$vuetify.breakpoint.mobile">
          <v-btn icon @click="navDrawerShow = !navDrawerShow">
            <v-icon v-text="'mdi-menu'"></v-icon>
          </v-btn>
        </v-col>
        <v-col class="pb-0" v-show="!$vuetify.breakpoint.mobile">
          <v-breadcrumbs
            :items="breadcrumbs"
            divider="/"
            class="py-2 px-0"
          ></v-breadcrumbs>
        </v-col>
        <v-col cols="3" class="pb-0" v-show="!$vuetify.breakpoint.mobile">
          <v-slider
            v-model="scrollSpeed"
            step="5"
            min="5"
            max="60"
            thumb-size="16"
            thumb-label
            dense
            hide-details
          >
            <template v-slot:label>
              <div class="text-caption">Scroll Speed</div>
            </template>
            <template v-slot:append>
              <v-icon
                v-if="autoScroll"
                color="primary"
                @click="autoScroll = !autoScroll"
                v-text="'mdi-pause'"
              ></v-icon>
              <v-icon
                v-else
                color="primary"
                @click="autoScroll = !autoScroll"
                v-text="'mdi-play'"
              ></v-icon>
            </template>
          </v-slider>
        </v-col>
        <v-col cols="12" class="fill-height py-0">
          <v-slide-y-transition mode="out-in">
            <router-view></router-view>
          </v-slide-y-transition>
        </v-col>
        <v-col cols="12" class="pa-0">
          <div id="bottom" v-intersect="onIntersectBottom"></div>
        </v-col>
      </v-row>
      <v-scale-transition>
        <v-btn
          v-show="autoScroll"
          fab
          v-scroll="onScroll"
          dark
          fixed
          bottom
          right
          color="primary"
          @click="autoScroll = !autoScroll"
        >
          <v-icon v-if="autoScroll">mdi-pause</v-icon>
          <v-icon v-else>mdi-play</v-icon>
        </v-btn>
      </v-scale-transition>
    </v-container>
  </div>
</template>

<script>
import { db } from "@/App.vue";
import { ref, onValue } from "firebase/database";

export default {
  name: "TournamentDashboardVue",

  data: () => ({
    tourName: "",
    category: "",
    navDrawerShow: null,
    navDrawerSelectedItem: "dashboard",
    navDrawerList: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        path: "dashboard",
      },
      {
        title: "Teams",
        icon: "mdi-account-group",
        path: "teams",
      },
      {
        title: "Team Matches",
        icon: "mdi-scoreboard",
        path: "team-match",
      },
      {
        title: "Rank",
        icon: "mdi-sort",
        path: "rank",
      },
      {
        title: "Schedule",
        icon: "mdi-calendar-clock",
        path: "schedule",
      },
      {
        title: "Bulletin",
        icon: "mdi-newspaper-variant-multiple",
        path: "bulletin",
      },
    ],
    autoScroll: false,
    scrollSpeed: 10,
    isIntersectTop: false,
    isIntersectBottom: false,
  }),
  created() {
    //Load Tournament
    const tournamentRef = ref(db, "tournaments/" + this.$route.params.id);
    onValue(tournamentRef, (snapshot) => {
      const tournament = snapshot.val();
      this.tournament = tournament;
      this.tourName = tournament.name;
      this.category = tournament.category;
    });
  },
  watch: {
    autoScroll: function (value) {
      if (value) {
        this.$vuetify.goTo("#bottom", {
          duration: this.scrollSpeed * 2 * 1000,
          easing: "linear",
        });
      }
    },
    isIntersectTop: function (value) {
      if (value && this.autoScroll) {
        this.$vuetify.goTo("#bottom", {
          duration: this.scrollSpeed * 2 * 1000,
          easing: "linear",
        });
      }
    },
    isIntersectBottom: function (value) {
      if (value && this.autoScroll) {
        this.$vuetify.goTo(0, {
          duration: this.scrollSpeed * 1000,
          easing: "linear",
        });
      }
    },
  },
  computed: {
    breadcrumbs() {
      const items = [];
      const nodes = this.$route.path.split("/");
      const matched = this.$route.matched;
      for (let i = 0; i < matched.length; i++) {
        const route = matched[i];
        if (route.name) {
          var path = "";
          for (let j = 1; j < route.path.split("/").length; j++) {
            path += "/" + nodes[j];
          }
          items.push({
            text: route.name,
            disabled:
              i == matched.length - 1 ||
              (i + 1 < matched.length && matched[i + 1].path.endsWith("/")),
            exact: true,
            to: path,
          });
        }
      }
      return items;
    },
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.isIntersectTop = top < 50;
    },
    onIntersectBottom(entries) {
      this.isIntersectBottom = entries[0].isIntersecting;
    },
  },
};
</script>
