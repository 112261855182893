<template>
  <v-container>
    <v-card class="rounded-lg">
      <v-container>
        <v-card-title>
          Bulletin
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            filled
            rounded
            dense
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="bulletinList"
          :search="search"
          @click:row="tableRowClicked"
        ></v-data-table>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import router from "../plugins/router";
import { db } from "@/App.vue";
import { ref, onValue } from "firebase/database";

export default {
  name: "TournamentBulletinVue",

  data: () => ({
    search: "",
    headers: [
      { text: "Title", value: "title" },
      { text: "Release Date", value: "date" },
    ],
    bulletinList: [],
    loading: true,
  }),

  created() {
    //Load Bulletin
    const bulletinRef = ref(db, "tournamentBulletin/" + this.$route.params.id);

    onValue(bulletinRef, (snapshot) => {
      this.bulletinList = [];
      snapshot.forEach((childSnapshot) => {
        const bulletin = childSnapshot.val();
        bulletin.id = childSnapshot.key;
        this.bulletinList.push(bulletin);
      });
      this.bulletinList.sort(function (a, b) {
        if (!a.date || !b.date) return 0;
        let x = a.date;
        let y = b.date;
        if (x < y) return 1;
        if (x > y) return -1;
        return 0;
      });
      this.loading = false;
    });
  },

  methods: {
    tableRowClicked: (item) => {
      router.push({
          name: "Bulletin Detail",
          params: {
            bulletinId: item.id,
          },
        });
    },
  },
};
</script>
