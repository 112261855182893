<template>
  <v-container fluid fill-height class="primary">
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 md4 lg3>
        <v-card class="rounded-lg" flat>
          <v-toolbar dense flat>
            <v-spacer></v-spacer>
            <v-card-subtitle class="mt-5 font-weight-bold text-center">
              "Your Bridge Pocket Account has successfully been deleted"
            </v-card-subtitle>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <v-container class="pt-0">
              <div class="mt-3 text-center">
                Deletion may take up to 30 days to be complete
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "ReqDeletionCompletedVue",

  data: () => ({}),
};
</script>
