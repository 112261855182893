<template>
  <v-container>
    <v-row>
      <v-col cols="12" :class="$vuetify.breakpoint.mobile ? 'px-0' : ''">
        <v-card class="rounded-lg px-2">
          <v-card-text>
            <v-row align="center" class="black--text">
              <v-col class="fill-height">
                <div
                  :class="!$vuetify.breakpoint.mobile ? 'headline' : 'title'"
                  v-text="match.ht"
                ></div>
              </v-col>
              <v-col cols="auto" align="right">
                <div
                  :class="
                    !$vuetify.breakpoint.mobile ? 'display-1' : 'headline'
                  "
                  v-text="
                    tournament.showCurrRoundImpVp == undefined ||
                    tournament.showCurrRoundImpVp
                      ? match.htIMP
                      : ''
                  "
                ></div>
                <div
                  :class="!$vuetify.breakpoint.mobile ? 'title' : 'subtitle'"
                  v-text="
                    (match.htIMP == match.htVP && match.vtIMP == match.vtVP) ||
                    !(
                      tournament.showCurrRoundImpVp == undefined ||
                      tournament.showCurrRoundImpVp
                    )
                      ? ''
                      : match.htVP
                  "
                ></div>
              </v-col>
              <v-col
                cols="auto"
                v-show="!$vuetify.breakpoint.mobile"
                align="center"
                class="pa-0"
              >
                <div
                  class="mt-2"
                  v-text="
                    !(
                      tournament.showCurrRoundImpVp == undefined ||
                      tournament.showCurrRoundImpVp
                    )
                      ? ''
                      : 'IMPs'
                  "
                ></div>
                <v-divider
                  v-show="
                    (match.htIMP != match.htVP || match.vtIMP != match.vtVP) &&
                    (tournament.showCurrRoundImpVp == undefined ||
                      tournament.showCurrRoundImpVp)
                  "
                  class="mt-1 mb-2"
                ></v-divider>
                <div
                  v-text="
                    (match.htIMP == match.htVP && match.vtIMP == match.vtVP) ||
                    !(
                      tournament.showCurrRoundImpVp == undefined ||
                      tournament.showCurrRoundImpVp
                    )
                      ? ''
                      : 'VPs'
                  "
                ></div>
              </v-col>
              <v-col
                cols="auto"
                v-show="$vuetify.breakpoint.mobile"
                align="center"
                class="pa-0"
              >
                <div class="" v-text="':'"></div>
              </v-col>
              <v-col cols="auto" align="left">
                <div
                  :class="
                    !$vuetify.breakpoint.mobile ? 'display-1' : 'headline'
                  "
                  v-text="
                    tournament.showCurrRoundImpVp == undefined ||
                    tournament.showCurrRoundImpVp
                      ? match.vtIMP
                      : ''
                  "
                ></div>
                <div
                  :class="!$vuetify.breakpoint.mobile ? 'title' : 'subtitle'"
                  v-text="
                    (match.htIMP == match.htVP && match.vtIMP == match.vtVP) ||
                    !(
                      tournament.showCurrRoundImpVp == undefined ||
                      tournament.showCurrRoundImpVp
                    )
                      ? ''
                      : match.vtVP
                  "
                ></div>
              </v-col>
              <v-col>
                <div
                  class="text-right"
                  :class="!$vuetify.breakpoint.mobile ? 'headline' : 'title'"
                  v-text="match.vt"
                ></div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        :class="$vuetify.breakpoint.mobile ? 'px-0' : ''"
        v-show="
          tournament.allowPeakLineup ||
          (match.htN && match.vtN) ||
          (boardList.length > 0 && (match.htN || match.vtN))
        "
      >
        <v-card class="rounded-lg">
          <v-card-text>
            <v-row class="black--text" align="center">
              <v-col cols="12" align="center">
                <div class="title" v-text="'Open Room'"></div>
              </v-col>
              <v-col cols="12" align="center">
                <div v-text="match.htN"></div>
              </v-col>
              <v-col align="center">
                <div v-text="match.vtW"></div>
              </v-col>
              <v-col cols="auto" align="center" class="pa-0">
                <v-card class="rounded-lg" color="primary" dark flat>
                  <v-card-text class="font-weight-bold">
                    <v-row>
                      <v-col cols="12" align="center">
                        <div v-text="'N'"></div>
                      </v-col>
                      <v-col cols="6" align="left">
                        <div v-text="'W'"></div>
                      </v-col>
                      <v-col cols="6" align="right">
                        <div v-text="'E'"></div>
                      </v-col>
                      <v-col cols="12" align="center">
                        <div v-text="'S'"></div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col align="center">
                <div v-text="match.vtE"></div>
              </v-col>
              <v-col cols="12" align="center">
                <div v-text="match.htS"></div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        :class="$vuetify.breakpoint.mobile ? 'px-0' : ''"
        v-show="
          tournament.allowPeakLineup ||
          (match.htN && match.vtN) ||
          (boardList.length > 0 && (match.htN || match.vtN))
        "
      >
        <v-card class="rounded-lg">
          <v-card-text>
            <v-row class="black--text" align="center">
              <v-col cols="12" align="center">
                <div class="title" v-text="'Closed Room'"></div>
              </v-col>
              <v-col cols="12" align="center">
                <div v-text="match.vtN"></div>
              </v-col>
              <v-col align="center">
                <div v-text="match.htW"></div>
              </v-col>
              <v-col cols="auto" align="center" class="pa-0">
                <v-card class="rounded-lg" color="primary" dark flat>
                  <v-card-text class="font-weight-bold">
                    <v-row>
                      <v-col cols="12" align="center">
                        <div v-text="'N'"></div>
                      </v-col>
                      <v-col cols="6" align="left">
                        <div v-text="'W'"></div>
                      </v-col>
                      <v-col cols="6" align="right">
                        <div v-text="'E'"></div>
                      </v-col>
                      <v-col cols="12" align="center">
                        <div v-text="'S'"></div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col align="center">
                <div v-text="match.htE"></div>
              </v-col>
              <v-col cols="12" align="center">
                <div v-text="match.vtS"></div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :class="$vuetify.breakpoint.mobile ? 'px-0' : ''">
        <v-card class="rounded-lg">
          <v-container>
            <v-data-table
              :headers="headers"
              :items="boardList"
              v-show="
                tournament.showCurrRoundBoards == undefined ||
                tournament.showCurrRoundBoards
              "
              hide-default-footer
              disable-pagination
              @click:row="tableRowClicked"
            ></v-data-table>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import router from "../plugins/router";
import { db } from "@/App.vue";
import { ref, onValue } from "firebase/database";

export default {
  name: "TournamentTeamMatchDetailVue",

  data: () => ({
    tournament: null,
    match: {},
    headers: [
      { text: "No", value: "board" },
      { text: "Contract", value: "contract1" },
      { text: "Score", value: "score1" },
      { text: "HT", value: "htIMP" },
      { text: "VT", value: "vtIMP" },
      { text: "Contract", value: "contract2" },
      { text: "Score", value: "score2" },
    ],
    boardList: [],
  }),

  created() {
    //Load Tournament
    const tournamentRef = ref(db, "tournaments/" + this.$route.params.id);
    onValue(tournamentRef, (snapshot) => {
      const tournament = snapshot.val();
      this.tournament = tournament;
      this.getData();
    });

    //Load TeamMatch
    const matchRef = ref(
      db,
      "tournamentTMs/" +
        this.$route.params.id +
        "/" +
        this.$route.params.round +
        "/" +
        this.$route.params.matchId
    );
    onValue(matchRef, (snapshot) => {
      const match = snapshot.val();
      match.id = snapshot.key;
      this.match = match;

      match.ht = "";
      match.vt = "";
      if (match.htId == "bye") {
        match.ht = "Bye";
        match.htIMP = "0";
        match.vtIMP = "0";
        match.htVP = "0";
      }
      if (match.vtId == "bye") {
        match.vt = "Bye";
        match.htIMP = "0";
        match.vtIMP = "0";
        match.vtVP = "0";
      }

      //Load Team Name
      const htRef = ref(
        db,
        "teams/" + this.$route.params.id + "/" + match.htId
      );
      onValue(htRef, (snapshot) => {
        const team = snapshot.val();
        if (team && team.teamName) match.ht = team.teamName;
      });

      const vtRef = ref(
        db,
        "teams/" + this.$route.params.id + "/" + match.vtId
      );
      onValue(vtRef, (snapshot) => {
        const team = snapshot.val();
        if (team && team.teamName) match.vt = team.teamName;
      });

      //Load Boards
      const boardRef = ref(db, "teamBoards/" + match.id);
      onValue(boardRef, (snapshot) => {
        this.boardList = [];
        snapshot.forEach((childSnapshot) => {
          const board = childSnapshot.val();
          board.htIMP = board.htIMP ? board.htIMP : "-";
          board.vtIMP = board.vtIMP ? board.vtIMP : "-";
          this.boardList.push(board);
        });
        this.boardList.sort(function (a, b) {
          if (!a.board || !b.board) return 0;
          let x = parseInt(a.board);
          let y = parseInt(b.board);
          if (x < y) return -1;
          if (x > y) return 1;
          return 0;
        });
      });
    });
  },

  methods: {
    tableRowClicked: () => {},
  },
};
</script>
