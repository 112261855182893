<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-card class="rounded-lg">
          <v-container>
            <div class="title text-center" v-text="tourName"></div>
          </v-container>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="rounded-lg">
          <v-card-text class="black--text">
            <v-row dense>
              <v-col cols="4" align="right">
                <div v-text="'ID :'"></div>
              </v-col>
              <v-col cols="8">
                <div v-text="tourId"></div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" align="right">
                <div v-text="'Date :'"></div>
              </v-col>
              <v-col cols="8">
                <div v-text="date"></div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" align="right">
                <div v-text="'Category :'"></div>
              </v-col>
              <v-col cols="8">
                <div v-text="category"></div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" align="right">
                <div v-text="'Level :'"></div>
              </v-col>
              <v-col cols="8">
                <div v-text="level"></div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" md="auto">
        <v-row>
          <v-col cols="12" md="auto">
            <v-card class="rounded-lg" width="100px">
              <v-card-text class="black--text">
                <v-row align="center" no-gutters>
                  <v-col cols="12" align="center">
                    <div
                      class="display-1 font-weight-bold"
                      v-text="teamCount"
                    ></div>
                  </v-col>
                  <v-col cols="12" align="center">
                    <div v-text="'Teams'"></div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="auto">
            <v-card class="rounded-lg" width="100px">
              <v-card-text class="black--text">
                <v-row align="center" no-gutters>
                  <v-col cols="12" align="center" class="d-flex justify-center">
                    <div
                      class="display-1 font-weight-bold"
                      v-text="currentRound"
                    ></div>
                    <div
                      class="title align-self-end"
                      v-text="'/' + maxRound"
                    ></div>
                  </v-col>
                  <v-col cols="12" align="center">
                    <div v-text="'Round'"></div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="menu in menuList" :key="menu.path" cols="4" sm="3" md="2">
        <v-card
          class="rounded-lg overflow-auto"
          height="130px"
          @click="menuClicked(menu)"
        >
          <v-card-text class="black--text fill-height">
            <v-row class="fill-height" align="center" no-gutters>
              <v-col cols="12" class="mt-3" align="center">
                <v-icon v-text="menu.icon" color="primary" large></v-icon>
              </v-col>
              <v-col cols="12" class="my-1" align="center">
                <div v-text="menu.title"></div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from "@/plugins/router";
import { db } from "@/App.vue";
import { ref, onValue } from "firebase/database";

export default {
  name: "TournamentVue",

  data: () => ({
    tourId: "",
    tourName: "",
    date: "",
    category: "",
    level: "",
    teamCount: 0,
    currentRound: 0,
    maxRound: 0,

    menuList: [
      {
        title: "Teams",
        icon: "mdi-account-group",
        path: "teams",
      },
      {
        title: "Team Matches",
        icon: "mdi-scoreboard",
        path: "team-match",
      },
      {
        title: "Rank",
        icon: "mdi-sort",
        path: "rank",
      },
      {
        title: "Schedule",
        icon: "mdi-calendar-clock",
        path: "schedule",
      },
      {
        title: "Bulletin",
        icon: "mdi-newspaper-variant-multiple",
        path: "bulletin",
      },
    ],
  }),
  created() {
    //Load Tournament
    const tournamentRef = ref(db, "tournaments/" + this.$route.params.id);
    onValue(tournamentRef, (snapshot) => {
      const tournament = snapshot.val();
      tournament.id = snapshot.key;
      this.tournament = tournament;
      this.tourId = tournament.id;
      this.tourName = tournament.name;
      this.date = tournament.date;
      this.level = tournament.level;
      this.category = tournament.category;
      this.currentRound = tournament.currentRound ? tournament.currentRound : 0;
      this.maxRound = tournament.maxRound ? tournament.maxRound : 0;
    });

    //Load Team Count
    const teamRef = ref(db, "teams/" + this.$route.params.id);

    onValue(teamRef, (snapshot) => {
      this.teamCount = snapshot.size;
    });
  },
  methods: {
    menuClicked: function (menu) {
      router.push("/tournament/" + this.$route.params.id + "/" + menu.path);
    },
  },
};
</script>
