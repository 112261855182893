<template>
  <div>
    <v-container>
      <v-card class="rounded-lg">
        <v-container>
          <v-card-title>
            {{ tournament ? tournament.name : "" }} -
            {{ teamList.length }} Teams
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              filled
              rounded
              dense
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn v-show="isAdmin" color="primary" @click="newItem"
              >Add New</v-btn
            >
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="teamList"
            :search="search"
            :options.sync="options"
            :loading="loading"
            @click:row="tableRowClicked"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                color="primary"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon color="red" small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-container>
    <v-navigation-drawer v-model="showTeamDetail" app clipped right>
      <v-container>
        <v-btn icon>
          <v-icon v-text="'mdi-close'" @click="showTeamDetail = false"></v-icon>
        </v-btn>
        <div
          class="mx-3 title"
          v-text="selectedTeam == null ? '' : selectedTeam.teamName"
        ></div>
        <v-list>
          <v-list-item
            v-for="player in selectedTeam == null ? null : selectedTeam.players"
            :key="player"
          >
            <v-list-item-content>
              <v-list-item-title v-text="player"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="official in selectedTeam == null
              ? null
              : selectedTeam.officials"
            :key="official.userId"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="official.name + ' (Official)'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-container>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.teamName"
                      label="Team's Name"
                      dense
                      required
                      :rules="[(v) => !!v || 'Required']"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    v-for="(value, key, i) in editedItem.players"
                    :key="key"
                  >
                    <v-text-field
                      ref="playerField"
                      v-model="editedItem.players[key]"
                      :label="'Player ' + (i + 1)"
                      append-icon="mdi-delete"
                      dense
                      required
                      :rules="[(v) => !!v || 'Required or Delete']"
                      @click:append="deletePlayer(key)"
                      @keydown.tab.prevent="addPlayer"
                      @paste="pastePlayer"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-btn color="primary" @click="addPlayer">
                      <v-icon>mdi-plus</v-icon> or press TAB
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close"> Cancel </v-btn>
            <v-btn color="primary" @click="save" dark> Save </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="380px">
      <v-card>
        <v-card-title
          >Are you sure you want to delete <br />
          "{{ editedItem.teamName }}"?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="red" dark @click="deleteItemConfirm">Delete</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/App.vue";
import { ref, onValue, set, push } from "firebase/database";
import { getAuth } from "firebase/auth";

export default {
  name: "TournamentTeamListVue",

  data: () => ({
    tournament: null,
    showTeamDetail: false,
    selectedTeam: null,
    editedItem: {
      teamName: "",
      players: { "player 0": "" },
    },
    defaultItem: {
      teamName: "",
      players: { "player 0": "" },
    },
    editedIndex: -1,
    search: "",
    headers: [],
    teamList: [],
    options: { itemsPerPage: -1 },
    loading: true,
    isAdmin: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    playerTrash: [],
  }),

  created() {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const uid = user.uid;
      //Load User Role
      const userRoleRef = ref(
        db,
        "users/" + uid + "/tournaments/" + this.$route.params.id
      );
      onValue(userRoleRef, (snapshot) => {
        const userRole = snapshot.val();
        this.isAdmin = userRole === "director";

        if (this.isAdmin) {
          this.headers = [
            { text: "Team's Name", value: "teamName" },
            { text: "Players (Click for Detail)", value: "playersConcat" },
            { text: "Actions", value: "actions", sortable: false },
          ];
        } else {
          this.headers = [
            { text: "Team's Name", value: "teamName" },
            { text: "Players (Click for Detail)", value: "playersConcat" },
          ];
        }
      });
    } else {
      this.headers = [
        { text: "Team's Name", value: "teamName" },
        { text: "Players (Click for Detail)", value: "playersConcat" },
      ];
    }

    //Load Tournament
    const tournamentRef = ref(db, "tournaments/" + this.$route.params.id);
    onValue(tournamentRef, (snapshot) => {
      this.tournament = snapshot.val();
    });

    //Load Teams
    const teamRef = ref(db, "teams/" + this.$route.params.id);

    onValue(teamRef, (snapshot) => {
      this.teamList = [];
      snapshot.forEach((childSnapshot) => {
        const team = childSnapshot.val();
        team.id = childSnapshot.key;

        let playersConcat = "";
        if (team.players) {
          Object.keys(team.players).forEach((key) => {
            playersConcat += team.players[key] + ", ";
          });
          playersConcat =
            playersConcat.length > 80
              ? playersConcat.substring(0, 80) + "..."
              : playersConcat.substring(0, playersConcat.length - 2);
        }
        team.playersConcat = playersConcat;

        this.teamList.push(team);
      });
      this.teamList.sort(function (a, b) {
        if (!a.teamName || !b.teamName) return 0;
        let x = a.teamName.toLowerCase();
        let y = b.teamName.toLowerCase();
        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
      });
      this.loading = false;
    });
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Team" : "Edit Team";
    },
  },

  methods: {
    tableRowClicked(item) {
      if (item == this.selectedTeam) this.showTeamDetail = !this.showTeamDetail;
      else this.showTeamDetail = true;
      this.selectedTeam = item;
    },

    newItem() {
      this.editedIndex = -1;
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.dialog = true;
    },

    setFocusToLastPlayerField() {
      this.$nextTick(() => {
        const nextField =
          this.$refs.playerField[
            Object.keys(this.editedItem.players).length - 1
          ];
        nextField.focus();
      });
    },

    addPlayer() {
      const newPlayers = {};
      this.$set(
        this.editedItem,
        "players",
        this.editedItem.players ? this.editedItem.players : {}
      );

      let i = 0;
      Object.keys(this.editedItem.players).forEach((key) => {
        const name = this.editedItem.players[key];
        if (!key.includes("player ")) {
          newPlayers[key] = name;
        } else if (name.trim() !== "") {
          newPlayers["player " + i++] = name;
        }
      });
      newPlayers["player " + i] = "";

      this.$set(this.editedItem, "players", newPlayers);

      this.setFocusToLastPlayerField();
    },

    pastePlayer(event) {
      const pastedData = event.clipboardData.getData("Text");
      const allPastedFields = pastedData
        .split(/[\r\n\t]+/)
        .filter((el) => el !== "");

      const newPlayers = {};
      let i = 0;
      Object.keys(this.editedItem.players).forEach((key) => {
        const name = this.editedItem.players[key];
        if (!key.includes("player ")) {
          newPlayers[key] = name;
        } else if (name.trim() !== "") {
          newPlayers["player " + i++] = name;
        }
      });
      allPastedFields.forEach((name) => {
        newPlayers["player " + i++] = name;
      });

      setTimeout(() => {
        this.$set(this.editedItem, "players", newPlayers);
        this.setFocusToLastPlayerField();
      });
    },

    editItem(item) {
      this.editedIndex = this.teamList.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },

    deletePlayer(key) {
      this.playerTrash.push(key);
      this.$delete(this.editedItem.players, key);

      const newPlayers = {};
      let i = 0;
      Object.keys(this.editedItem.players).forEach((key) => {
        const name = this.editedItem.players[key];
        if (!key.includes("player ")) {
          newPlayers[key] = name;
        } else if (name.trim() !== "") {
          newPlayers["player " + i++] = name;
        }
      });

      this.$set(this.editedItem, "players", newPlayers);
    },

    deleteItem(item) {
      this.editedIndex = this.teamList.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // delete user's tournament
      if (this.editedItem.players) {
        Object.keys(this.editedItem.players).forEach((key) => {
          if (key.includes("player ")) return;
          set(
            ref(db, "users/" + key + "/tournaments/" + this.$route.params.id),
            null
          );
        });
      }

      // delete team
      set(
        ref(db, "teams/" + this.$route.params.id + "/" + this.editedItem.id),
        null
      );
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
        this.editedIndex = -1;
      });
    },

    save() {
      if (!this.$refs.form.validate()) return;

      if (this.editedIndex > -1) {
        // delete players on trash
        this.playerTrash.forEach((key) => {
          if (key.includes("player ")) return;
          set(
            ref(db, "users/" + key + "/tournaments/" + this.$route.params.id),
            null
          );
        });

        // update team
        delete this.editedItem.playersConcat;
        set(
          ref(db, "teams/" + this.$route.params.id + "/" + this.editedItem.id),
          this.editedItem
        );
      } else {
        // create new
        const teamRef = ref(db, "teams/" + this.$route.params.id);
        push(teamRef, this.editedItem);
      }
      this.close();
    },
  },
};
</script>
