<template>
  <v-container>
    <v-card class="rounded-lg">
      <v-tabs
        v-model="currentRound"
        background-color="primary"
        dark
        centered
        center-active
      >
        <v-tab v-for="round in maxRound" :key="round">
          Round {{ round }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentRound" touchless>
        <v-tab-item v-for="round in maxRound" :key="round">
          <v-container>
            <v-card flat>
              <v-card-title>
                {{ tournament ? tournament.name : "" }} - Round {{ round }}
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  filled
                  rounded
                  dense
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="teamRankList[currentRound]"
                :search="search"
                :options.sync="options"
                :loading="loading"
                :mobile-breakpoint="0"
                @click:row="tableRowClicked"
              >
              </v-data-table>
            </v-card>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import router from "../plugins/router";
import { db } from "@/App.vue";
import { ref, onValue } from "firebase/database";

export default {
  name: "TournamentTeamRankVue",

  data: () => ({
    tournament: null,
    maxRound: 1,
    currentRound: 0,
    search: "",
    headers: [
      { text: "Rank", value: "rank", align: "end" },
      { text: "Name", value: "teamName" },
      { text: "Quo", value: "quo" },
      { text: "VPs", value: "vp" },
    ],
    teamRankList: [],
    options: { itemsPerPage: -1 },
    loading: true,
  }),
  created() {
    //Load Tournament
    const tournamentRef = ref(db, "tournaments/" + this.$route.params.id);
    onValue(tournamentRef, (snapshot) => {
      const tournament = snapshot.val();
      this.tournament = tournament;
      this.maxRound = tournament.maxRound ? parseInt(tournament.maxRound) : 1;
      this.currentRound = tournament.currentRound
        ? parseInt(tournament.currentRound) - 1
        : 0;
      this.getData();
    });
  },
  methods: {
    tableRowClicked: (item) => {
      router.push("teams/" + item.id);
    },
    getData: function () {
      this.loading = true;
      //Load Rank
      const teamRef = ref(db, "teams/" + this.$route.params.id);

      onValue(teamRef, (snapshot) => {
        const teams = [];
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const team = childSnapshot.val();
          team.id = childKey;
          teams.push(team);
        });

        const teamScoreRef = ref(db, "teamScore/" + this.$route.params.id);

        onValue(teamScoreRef, (snapshot) => {
          const teamRanks = [];

          //load score per team per round
          teams.forEach((team) => {
            team.teamScores = [];
            var totalImpWe = 0;
            var totalImpThey = 0;
            var totalVp = 0;
            for (let i = 1; i <= this.maxRound; i++) {
              const score = snapshot
                .child(team.id)
                .child("Round " + i)
                .val();
              if (score) {
                if (
                  this.tournament.showCurrRoundImpVp ||
                  i < this.tournament.currentRound
                ) {
                  totalImpWe += score.impWe ? score.impWe : 0;
                  totalImpThey += score.impThey ? score.impThey : 0;
                  totalVp += score.vp ? score.vp : 0;
                }
                score.totalImpWe = totalImpWe;
                score.totalImpThey = totalImpThey;
                score.totalVp = totalVp;
                team.teamScores.push(score);
              } else {
                const newScore = {
                  totalImpWe: totalImpWe,
                  totalImpThey: totalImpThey,
                  totalVp: totalVp,
                };
                team.teamScores.push(newScore);
              }
            }
          });

          //sorting per round
          for (let roundIndex = 0; roundIndex < this.maxRound; roundIndex++) {
            const roundTeamList = JSON.parse(JSON.stringify(teams));
            teamRanks.push(roundTeamList);

            for (let j = 1; j < roundTeamList.length; j++) {
              for (let k = j; k > 0; k--) {
                const teamScoreNext = roundTeamList[k].teamScores[roundIndex];
                const teamScoreNow =
                  roundTeamList[k - 1].teamScores[roundIndex];
                if (teamScoreNext == null || teamScoreNow == null) continue;

                const vpNext = teamScoreNext.totalVp;
                const vpNow = teamScoreNow.totalVp;
                const quoNext =
                  (1.0 * teamScoreNext.totalImpWe) / teamScoreNext.totalImpThey;
                const quoNow =
                  (1.0 * teamScoreNow.totalImpWe) / teamScoreNow.totalImpThey;

                roundTeamList[k].vp = Number(vpNext).toFixed(2);
                roundTeamList[k - 1].vp = Number(vpNow).toFixed(2);
                roundTeamList[k].quo = Number(quoNext).toFixed(4);
                roundTeamList[k - 1].quo = Number(quoNow).toFixed(4);
                if (vpNext > vpNow) {
                  const temp = roundTeamList[k];
                  roundTeamList[k] = roundTeamList[k - 1];
                  roundTeamList[k - 1] = temp;
                } else if (vpNext == vpNow && quoNext > quoNow) {
                  const temp = roundTeamList[k];
                  roundTeamList[k] = roundTeamList[k - 1];
                  roundTeamList[k - 1] = temp;
                }
              }
            }
            for (let j = 0; j < roundTeamList.length; j++) {
              const rank = j + 1;
              const teamScoreNow = roundTeamList[j].teamScores[roundIndex];
              if (teamScoreNow == null) continue;
              teamScoreNow.rank = rank;
              roundTeamList[j].rank = rank;

              for (let k = j + 1; k < roundTeamList.length; k++) {
                const teamScoreNext = roundTeamList[k].teamScores[roundIndex];
                if (teamScoreNext == null) continue;
                const quoNext =
                  (1.0 * teamScoreNext.totalImpWe) / teamScoreNext.totalImpThey;
                const quoNow =
                  (1.0 * teamScoreNow.totalImpWe) / teamScoreNow.totalImpThey;
                if (
                  teamScoreNext.totalVp == teamScoreNow.totalVp &&
                  quoNext == quoNow
                ) {
                  teamScoreNext.rank = rank;
                  roundTeamList[k].rank = rank;
                  j++;
                } else {
                  break;
                }
              }
            }
            if (teamRanks.length == this.maxRound) this.loading = false;
          }
          this.teamRankList = teamRanks;
        });
      });
    },
  },
};
</script>
