import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home";
import SignIn from "../components/SignIn";
import ReqDeletion from "../components/ReqDeletion.vue";
import ReqDeletionSigned from "../components/ReqDeletionSigned.vue";
import ReqDeletionCompleted from "../components/ReqDeletionCompleted.vue";
import Tournament from "../components/Tournament";
import Timer from "../components/Timer";
import RouterView from "../components/RouterView";
import TournamentLayout from "../components/TournamentLayout";
import TournamentTeamDashboard from "../components/TournamentTeamDashboard";
import TournamentTeamList from "../components/TournamentTeamList";
import TournamentTeamMatchList from "../components/TournamentTeamMatchList";
import TournamentTeamMatchDetail from "../components/TournamentTeamMatchDetail";
import TournamentTeamRank from "../components/TournamentTeamRank";
import TournamentSchedule from "../components/TournamentSchedule";
import TournamentBulletin from "../components/TournamentBulletin";
import TournamentBulletinDetail from "../components/TournamentBulletinDetail";

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    { name: "Home", path: "/", component: Home, alias: "/home" },
    { name: "Login", path: "/sign-in", component: SignIn },
    { name: "Account Deletion Request", path: "/account-deletion", component: ReqDeletion },
    { name: "Account Deletion Request - Signed", path: "/account-deletion-signed", component: ReqDeletionSigned },
    { name: "Account Deletion Request - Successfull", path: "/account-deletion-completed", component: ReqDeletionCompleted },
    {
      name: "Tournament",
      path: "/tournament",
      component: RouterView,
      children: [
        {
          path: "",
          component: Tournament,
        },
        {
          name: "Dashboard",
          path: ":id",
          component: TournamentLayout,
          children: [
            {
              path: "",
              component: TournamentTeamDashboard,
              alias: "dashboard",
            },
            {
              name: "Teams",
              path: "teams",
              component: TournamentTeamList,
            },
            {
              name: "Team Matches",
              path: "team-match",
              component: RouterView,
              children: [
                {
                  path: "",
                  component: TournamentTeamMatchList,
                },
                {
                  name: "Team Match Detail",
                  path: ":round/:matchId",
                  component: TournamentTeamMatchDetail,
                },
              ],
            },
            {
              name: "Rank",
              path: "rank",
              component: TournamentTeamRank,
            },
            {
              name: "Schedule",
              path: "schedule",
              component: TournamentSchedule,
            },
            {
              name: "Bulletin",
              path: "bulletin",
              component: RouterView,
              children: [
                {
                  path: "",
                  component: TournamentBulletin,
                },
                {
                  name: "Bulletin Detail",
                  path: ":bulletinId",
                  component: TournamentBulletinDetail,
                },
              ],
            },
          ],
        },
      ],
    },
    { name: "timer", path: "/timer", component: Timer },
  ],
});
