<template>
  <v-slide-y-transition mode="out-in">
    <router-view></router-view>
  </v-slide-y-transition>
</template>

<script>
// import router from '../plugins/router'

export default {
  name: "RouterViewVue",

  data: () => ({}),
};
</script>
