<template>
  <v-container fluid fill-height class="primary">
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 md4 lg3>
        <v-card class="rounded-lg" flat>
          <v-toolbar dense flat>
            <v-spacer></v-spacer>
            <v-card-subtitle class="mt-5 font-weight-bold text-center">
              "Are you sure you want to delete your Bridge Pocket account?"
            </v-card-subtitle>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <v-container class="pt-0">
              <div class="mt-3 text-center">
                You have been logged in
              </div>
              <v-btn
                id="loginBtn"
                class="mt-3"
                block
                dark
                color="primary"
                @click="DeleteAccount"
                v-text="'Yes, Please Delete My Account'"
              ></v-btn>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import router from "../plugins/router";

export default {
  name: "ReqDeletionSignedVue",

  data: () => ({}),

  methods: {
    DeleteAccount: function () {
      router.replace("/account-deletion-completed");
    },
  },
};
</script>
