<template>
  <v-container class="fill-height">
    <v-card class="rounded-lg fill-height" width="100%" height="100%">
      <iframe
        :src="bulletin.url"
        width="100%"
        height="100%"
        style="border: none"
      ></iframe>
    </v-card>
  </v-container>
</template>

<script>
import { db } from "@/App.vue";
import { ref, onValue } from "firebase/database";

export default {
  name: "TournamentBulletinVue",

  data: () => ({
    bulletin: {},
  }),

  created() {
    //Load Bulletin
    const bulletinRef = ref(
      db,
      "tournamentBulletin/" +
        this.$route.params.id +
        "/" +
        this.$route.params.bulletinId
    );
    onValue(bulletinRef, (snapshot) => {
      const bulletin = snapshot.val();
      bulletin.id = snapshot.key;
      if (bulletin.url.includes("drive.google.com")) {
        bulletin.url = bulletin.url.replace("/view", "/preview")
      }
      this.bulletin = bulletin;
    });
  },
};
</script>
