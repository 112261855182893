<template>
  <v-container>
    <v-card flat>
      <v-card-title>
        Tournament
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          filled
          rounded
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="!$vuetify.breakpoint.mobile ? headerDesktop : headerMobile"
        :items="tournamentList"
        :search="search"
        :loading="loading"
        :mobile-breakpoint="0"
        @click:row="tableRowClicked"
      >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import router from "../plugins/router";
import { db } from "@/App.vue";
import { ref, onValue } from "firebase/database";

export default {
  name: "TournamentVue",

  data: () => ({
    search: "",
    headerDesktop: [
      { text: "Tournament", value: "name" },
      { text: "Category", value: "category" },
      { text: "Level", value: "level" },
      { text: "Status", value: "status" },
      { text: "Date", value: "date" },
      { text: "Last Update", value: "lastUpdate" },
    ],
    headerMobile: [
      { text: "Tournament", value: "name" },
      { text: "Category", value: "category" },
      { text: "Level", value: "level" },
    ],
    tournamentList: [],
    loading: true,
  }),
  created() {
    const onStreamRef = ref(db, "tournamentOnStream");

    onValue(onStreamRef, (snapshot) => {
      this.tournamentList = [];

      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const tournamentRef = ref(db, "tournaments/" + childKey);
        const tournament = { id: childKey };
        this.tournamentList.push(tournament);

        onValue(tournamentRef, (snapshot) => {
          const data = snapshot.val();
          Object.assign(tournament, data);
          this.tournamentList.sort(function (a, b) {
            if (!a.lastUpdate || !b.lastUpdate) return 0;
            let x = a.lastUpdate.toLowerCase();
            let y = b.lastUpdate.toLowerCase();
            if (x < y) return 1;
            if (x > y) return -1;
            return 0;
          });
        });
      });
      this.loading = false;
    });
  },
  methods: {
    tableRowClicked: (item) => {
      router.push("/tournament/" + item.id + "/dashboard");
    },
  },
};
</script>
