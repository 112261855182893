<template>
  <div>
    <!-- <HomeMobile v-show="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"/> -->
    <!-- <HomeDesktop v-show="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"/> -->
    <HomeDesktop/>
  </div>
</template>
  
<script>
import HomeDesktop from './HomeDesktop.vue';
// import HomeMobile from './HomeMobile.vue';

export default {
    name: "HomeVue",

    data: () => ({
    }),
    
    components: { HomeDesktop }
}
</script>
  