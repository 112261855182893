var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-app-bar',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],class:{
      expand:
        !_vm.$vuetify.breakpoint.mobile && !_vm.scrolled && _vm.$route.name == 'Home',
    },attrs:{"app":"","clipped-left":"","clipped-right":"","color":!_vm.$vuetify.breakpoint.mobile && !_vm.scrolled && _vm.$route.name == 'Home'
        ? 'transparent'
        : 'primary',"dark":_vm.$vuetify.breakpoint.mobile || _vm.scrolled || _vm.$route.name != 'Home',"flat":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mobile),expression:"!$vuetify.breakpoint.mobile"}],staticStyle:{"height":"40px","width":"40px","background-color":"white","border-radius":"6px","position":"absolute","left":"16px"}}),_c('v-img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mobile),expression:"!$vuetify.breakpoint.mobile"}],staticClass:"shrink mr-2",attrs:{"alt":"Bridge Pocket Logo","contain":"","src":require('./assets/logo-512px.png'),"transition":"scale-transition","width":"40"}}),_c('v-app-bar-nav-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mobile && _vm.isTournamentDashboard()),expression:"$vuetify.breakpoint.mobile && isTournamentDashboard()"}],on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-arrow-left')}})],1),(_vm.$vuetify.breakpoint.mobile && _vm.isTournamentDashboard())?_c('v-app-bar-title',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(
        _vm.$route.name ? 'Bridge Pocket - ' + _vm.$route.name : 'Bridge Pocket'
      )}}):_c('v-app-bar-title',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s('Bridge Pocket')}}),_c('v-spacer'),_vm._l((_vm.menuList),function(menu,index){return _c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mobile),expression:"!$vuetify.breakpoint.mobile"}],key:index,attrs:{"text":""},domProps:{"textContent":_vm._s(menu.title)},on:{"click":function($event){return _vm.routeView(menu.path)}}})}),_c('v-spacer'),_vm._l((_vm.loggedInBtnList),function(menu){return _c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.loggedIn),expression:"loggedIn"}],key:menu.id,attrs:{"icon":""}},[_c('v-icon',{domProps:{"textContent":_vm._s(menu.icon)}})],1)}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.loggedIn),expression:"loggedIn"}],attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.navBottomList[3].icon)}})],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Profile")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
        !_vm.loggedIn && !(_vm.isTournamentDashboard() && _vm.$vuetify.breakpoint.mobile)
      ),expression:"\n        !loggedIn && !(isTournamentDashboard() && $vuetify.breakpoint.mobile)\n      "}],attrs:{"color":"white","text":""},on:{"click":function($event){return _vm.routeView('sign-in')}}},[_vm._v(" Login ")])],2),_c('v-main',{class:_vm.isTournamentDashboard() ? 'grey lighten-4' : '',attrs:{"app":""}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1),_c('v-bottom-navigation',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mobile && !_vm.isTournamentDashboard()),expression:"$vuetify.breakpoint.mobile && !isTournamentDashboard()"}],attrs:{"color":"primary","grow":"","app":""},on:{"change":_vm.routeView},model:{value:(_vm.navCurrent),callback:function ($$v) {_vm.navCurrent=$$v},expression:"navCurrent"}},_vm._l((_vm.navBottomList),function(menu,index){return _c('v-btn',{key:index,attrs:{"value":menu.path}},[_c('span',{domProps:{"textContent":_vm._s(menu.title)}}),_c('v-icon',{domProps:{"textContent":_vm._s(menu.icon)}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }