<template>
  <div>
    <section id="top">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col cols="12" style="position: absolute">
            <div
              class="background-wave"
              height="720px"
              :style="
                !$vuetify.breakpoint.mobile
                  ? 'position: absolute; left: 50%; top: -100px'
                  : 'position: absolute; right: 0; top: -100px'
              "
            >
              <div class="primary" height="100%" width="100%">
                <v-img
                  class="deco-wave"
                  style="position: relative; top: 10px"
                  height="720"
                  :src="require('../assets/deco-wave-light.png')"
                ></v-img>
                <v-img
                  class="deco-line"
                  style="
                    position: absolute;
                    top: -65px;
                    left: -16px;
                    opacity: 0.56;
                  "
                  height="720"
                  :src="require('../assets/deco-line-light.png')"
                ></v-img>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            :class="!$vuetify.breakpoint.mobile ? 'pl-12 pt-16' : 'px-8 pt-8'"
            style="position: relative"
          >
            <h1
              class="display-3 font-weight-bold mt-3"
              :class="$vuetify.breakpoint.mobile ? 'yellow--text' : ''"
            >
              Welcome to
            </h1>
            <h1
              class="display-3 font-weight-bold mb-3"
              :class="
                $vuetify.breakpoint.mobile ? 'white--text' : 'primary--text'
              "
            >
              Bridge Pocket
            </h1>
            <p
              class="display-1"
              :class="$vuetify.breakpoint.mobile ? 'white--text' : ''"
            >
              Bring another experience in bridge ecosystem
            </p>
            <a
              href="https://play.google.com/store/apps/details?id=com.pencit.abdur.bridgepocket&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              <img
                contain
                height="60"
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
          </v-col>
          <v-col cols="12" md="6">
            <v-img
              class="mockup-top"
              :height="!$vuetify.breakpoint.mobile ? 500 : 300"
              contain
              :src="require('../assets/mockup-1.png')"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="statistic" style="position: relative">
      <v-container>
        <v-row>
          <v-col cols="12" md="4" align="center">
            <h1 class="display-3 font-weight-bold primary--text">10K+</h1>
            <p class="display-1">Downloads</p>
          </v-col>
          <v-col cols="12" md="4" align="center">
            <h1 class="display-3 font-weight-bold primary--text">100+</h1>
            <p class="display-1">Tournaments</p>
          </v-col>
          <v-col cols="12" md="4" align="center">
            <h1 class="display-3 font-weight-bold primary--text">4.8/5</h1>
            <p class="display-1">Rating</p>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="footer" v-show="!$vuetify.breakpoint.mobile">
      <v-footer dark padless>
        <v-card flat tile color="primary" class="flex white--text text-center">
          <v-card-text>
            <v-btn
              v-for="(socmed, index) in socmedList"
              :key="index"
              class="mx-4 white--text"
              icon
            >
              <v-icon
                size="24px"
                v-text="socmed.icon"
                @click="openUrl(socmed.url)"
              ></v-icon>
            </v-btn>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text">
            {{ new Date().getFullYear() }} — <strong>Bridge Pocket</strong>
          </v-card-text>
        </v-card>
      </v-footer>
    </section>
    <v-scale-transition>
      <v-btn
        fab
        v-show="fab"
        v-scroll="onScroll"
        :class="$vuetify.breakpoint.mobile ? 'mb-12' : ''"
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
  </div>
</template>

<script>
export default {
  name: "HomeDesktopVue",

  data: () => ({
    socmedList: [
      {
        icon: "mdi-linkedin",
        url: "https://www.linkedin.com/services/products/pencit-studio-bridge-pocket?trk=organization_guest_main_product_card&originalSubdomain=id",
      },
      {
        icon: "mdi-instagram",
        url: "https://www.instagram.com/bridgepocket/",
      },
      {
        icon: "mdi-youtube",
        url: "https://www.youtube.com/channel/UCR9uU8oAiZutIF5CdHwPYDg",
      },
      {
        icon: "mdi-facebook",
        url: "https://www.facebook.com/bridgepocket",
      },
    ],
    fab: null,
    color: "",
    flat: null,
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
