var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.mobile ? 'px-0' : '',attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg px-2"},[_c('v-card-text',[_c('v-row',{staticClass:"black--text",attrs:{"align":"center"}},[_c('v-col',{staticClass:"fill-height"},[_c('div',{class:!_vm.$vuetify.breakpoint.mobile ? 'headline' : 'title',domProps:{"textContent":_vm._s(_vm.match.ht)}})]),_c('v-col',{attrs:{"cols":"auto","align":"right"}},[_c('div',{class:!_vm.$vuetify.breakpoint.mobile ? 'display-1' : 'headline',domProps:{"textContent":_vm._s(
                  _vm.tournament.showCurrRoundImpVp == undefined ||
                  _vm.tournament.showCurrRoundImpVp
                    ? _vm.match.htIMP
                    : ''
                )}}),_c('div',{class:!_vm.$vuetify.breakpoint.mobile ? 'title' : 'subtitle',domProps:{"textContent":_vm._s(
                  (_vm.match.htIMP == _vm.match.htVP && _vm.match.vtIMP == _vm.match.vtVP) ||
                  !(
                    _vm.tournament.showCurrRoundImpVp == undefined ||
                    _vm.tournament.showCurrRoundImpVp
                  )
                    ? ''
                    : _vm.match.htVP
                )}})]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mobile),expression:"!$vuetify.breakpoint.mobile"}],staticClass:"pa-0",attrs:{"cols":"auto","align":"center"}},[_c('div',{staticClass:"mt-2",domProps:{"textContent":_vm._s(
                  !(
                    _vm.tournament.showCurrRoundImpVp == undefined ||
                    _vm.tournament.showCurrRoundImpVp
                  )
                    ? ''
                    : 'IMPs'
                )}}),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(
                  (_vm.match.htIMP != _vm.match.htVP || _vm.match.vtIMP != _vm.match.vtVP) &&
                  (_vm.tournament.showCurrRoundImpVp == undefined ||
                    _vm.tournament.showCurrRoundImpVp)
                ),expression:"\n                  (match.htIMP != match.htVP || match.vtIMP != match.vtVP) &&\n                  (tournament.showCurrRoundImpVp == undefined ||\n                    tournament.showCurrRoundImpVp)\n                "}],staticClass:"mt-1 mb-2"}),_c('div',{domProps:{"textContent":_vm._s(
                  (_vm.match.htIMP == _vm.match.htVP && _vm.match.vtIMP == _vm.match.vtVP) ||
                  !(
                    _vm.tournament.showCurrRoundImpVp == undefined ||
                    _vm.tournament.showCurrRoundImpVp
                  )
                    ? ''
                    : 'VPs'
                )}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mobile),expression:"$vuetify.breakpoint.mobile"}],staticClass:"pa-0",attrs:{"cols":"auto","align":"center"}},[_c('div',{domProps:{"textContent":_vm._s(':')}})]),_c('v-col',{attrs:{"cols":"auto","align":"left"}},[_c('div',{class:!_vm.$vuetify.breakpoint.mobile ? 'display-1' : 'headline',domProps:{"textContent":_vm._s(
                  _vm.tournament.showCurrRoundImpVp == undefined ||
                  _vm.tournament.showCurrRoundImpVp
                    ? _vm.match.vtIMP
                    : ''
                )}}),_c('div',{class:!_vm.$vuetify.breakpoint.mobile ? 'title' : 'subtitle',domProps:{"textContent":_vm._s(
                  (_vm.match.htIMP == _vm.match.htVP && _vm.match.vtIMP == _vm.match.vtVP) ||
                  !(
                    _vm.tournament.showCurrRoundImpVp == undefined ||
                    _vm.tournament.showCurrRoundImpVp
                  )
                    ? ''
                    : _vm.match.vtVP
                )}})]),_c('v-col',[_c('div',{staticClass:"text-right",class:!_vm.$vuetify.breakpoint.mobile ? 'headline' : 'title',domProps:{"textContent":_vm._s(_vm.match.vt)}})])],1)],1)],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.tournament.allowPeakLineup ||
        (_vm.match.htN && _vm.match.vtN) ||
        (_vm.boardList.length > 0 && (_vm.match.htN || _vm.match.vtN))
      ),expression:"\n        tournament.allowPeakLineup ||\n        (match.htN && match.vtN) ||\n        (boardList.length > 0 && (match.htN || match.vtN))\n      "}],class:_vm.$vuetify.breakpoint.mobile ? 'px-0' : '',attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-text',[_c('v-row',{staticClass:"black--text",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('div',{staticClass:"title",domProps:{"textContent":_vm._s('Open Room')}})]),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('div',{domProps:{"textContent":_vm._s(_vm.match.htN)}})]),_c('v-col',{attrs:{"align":"center"}},[_c('div',{domProps:{"textContent":_vm._s(_vm.match.vtW)}})]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"auto","align":"center"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"color":"primary","dark":"","flat":""}},[_c('v-card-text',{staticClass:"font-weight-bold"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('div',{domProps:{"textContent":_vm._s('N')}})]),_c('v-col',{attrs:{"cols":"6","align":"left"}},[_c('div',{domProps:{"textContent":_vm._s('W')}})]),_c('v-col',{attrs:{"cols":"6","align":"right"}},[_c('div',{domProps:{"textContent":_vm._s('E')}})]),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('div',{domProps:{"textContent":_vm._s('S')}})])],1)],1)],1)],1),_c('v-col',{attrs:{"align":"center"}},[_c('div',{domProps:{"textContent":_vm._s(_vm.match.vtE)}})]),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('div',{domProps:{"textContent":_vm._s(_vm.match.htS)}})])],1)],1)],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.tournament.allowPeakLineup ||
        (_vm.match.htN && _vm.match.vtN) ||
        (_vm.boardList.length > 0 && (_vm.match.htN || _vm.match.vtN))
      ),expression:"\n        tournament.allowPeakLineup ||\n        (match.htN && match.vtN) ||\n        (boardList.length > 0 && (match.htN || match.vtN))\n      "}],class:_vm.$vuetify.breakpoint.mobile ? 'px-0' : '',attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-text',[_c('v-row',{staticClass:"black--text",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('div',{staticClass:"title",domProps:{"textContent":_vm._s('Closed Room')}})]),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('div',{domProps:{"textContent":_vm._s(_vm.match.vtN)}})]),_c('v-col',{attrs:{"align":"center"}},[_c('div',{domProps:{"textContent":_vm._s(_vm.match.htW)}})]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"auto","align":"center"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"color":"primary","dark":"","flat":""}},[_c('v-card-text',{staticClass:"font-weight-bold"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('div',{domProps:{"textContent":_vm._s('N')}})]),_c('v-col',{attrs:{"cols":"6","align":"left"}},[_c('div',{domProps:{"textContent":_vm._s('W')}})]),_c('v-col',{attrs:{"cols":"6","align":"right"}},[_c('div',{domProps:{"textContent":_vm._s('E')}})]),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('div',{domProps:{"textContent":_vm._s('S')}})])],1)],1)],1)],1),_c('v-col',{attrs:{"align":"center"}},[_c('div',{domProps:{"textContent":_vm._s(_vm.match.htE)}})]),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('div',{domProps:{"textContent":_vm._s(_vm.match.vtS)}})])],1)],1)],1)],1),_c('v-col',{class:_vm.$vuetify.breakpoint.mobile ? 'px-0' : '',attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-container',[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.tournament.showCurrRoundBoards == undefined ||
              _vm.tournament.showCurrRoundBoards
            ),expression:"\n              tournament.showCurrRoundBoards == undefined ||\n              tournament.showCurrRoundBoards\n            "}],attrs:{"headers":_vm.headers,"items":_vm.boardList,"hide-default-footer":"","disable-pagination":""},on:{"click:row":_vm.tableRowClicked}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }