<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
}
</style>

<template>
  <v-app>
    <v-app-bar
      app
      clipped-left
      clipped-right
      :class="{
        expand:
          !$vuetify.breakpoint.mobile && !scrolled && $route.name == 'Home',
      }"
      :color="
        !$vuetify.breakpoint.mobile && !scrolled && $route.name == 'Home'
          ? 'transparent'
          : 'primary'
      "
      :dark="$vuetify.breakpoint.mobile || scrolled || $route.name != 'Home'"
      v-scroll="onScroll"
      flat
    >
      <div
        v-show="!$vuetify.breakpoint.mobile"
        style="
          height: 40px;
          width: 40px;
          background-color: white;
          border-radius: 6px;
          position: absolute;
          left: 16px;
        "
      ></div>
      <v-img
        v-show="!$vuetify.breakpoint.mobile"
        alt="Bridge Pocket Logo"
        class="shrink mr-2"
        contain
        :src="require('./assets/logo-512px.png')"
        transition="scale-transition"
        width="40"
      />

      <v-app-bar-nav-icon
        v-show="$vuetify.breakpoint.mobile && isTournamentDashboard()"
        @click="$router.back()"
      >
        <v-icon v-text="'mdi-arrow-left'"></v-icon>
      </v-app-bar-nav-icon>

      <v-app-bar-title
        v-if="$vuetify.breakpoint.mobile && isTournamentDashboard()"
        class="font-weight-medium"
        v-text="
          $route.name ? 'Bridge Pocket - ' + $route.name : 'Bridge Pocket'
        "
      />
      <v-app-bar-title
        v-else
        class="font-weight-medium"
        v-text="'Bridge Pocket'"
      />

      <v-spacer></v-spacer>

      <v-btn
        v-for="(menu, index) in menuList"
        :key="index"
        v-show="!$vuetify.breakpoint.mobile"
        v-text="menu.title"
        @click="routeView(menu.path)"
        text
      ></v-btn>

      <v-spacer></v-spacer>

      <v-btn
        v-for="menu in loggedInBtnList"
        :key="menu.id"
        v-show="loggedIn"
        icon
      >
        <v-icon v-text="menu.icon"></v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" v-show="loggedIn">
            <v-icon v-text="navBottomList[3].icon"></v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-show="
          !loggedIn && !(isTournamentDashboard() && $vuetify.breakpoint.mobile)
        "
        color="white"
        text
        @click="routeView('sign-in')"
      >
        Login
      </v-btn>
    </v-app-bar>

    <v-main app :class="isTournamentDashboard() ? 'grey lighten-4' : ''">
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>

    <v-bottom-navigation
      v-show="$vuetify.breakpoint.mobile && !isTournamentDashboard()"
      color="primary"
      v-model="navCurrent"
      @change="routeView"
      grow
      app
    >
      <v-btn
        v-for="(menu, index) in navBottomList"
        :key="index"
        :value="menu.path"
      >
        <span v-text="menu.title"></span>
        <v-icon v-text="menu.icon"></v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import router from "./plugins/router";
import { getDatabase } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

let db;
export { db };

export default {
  name: "App",

  data: () => ({
    loggedIn: false,
    scrolled: false,
    menuList: [
      {
        title: "Home",
        path: "home",
      },
      {
        title: "Post",
        path: "post",
      },
      {
        title: "Tournament",
        path: "tournament",
      },
      {
        title: "Timer",
        path: "timer",
      },
      {
        title: "About Us",
        path: "about-us",
      },
      {
        title: "Contact",
        path: "contact",
      },
    ],
    navBottomList: [
      {
        title: "Home",
        icon: "mdi-home",
        path: "home",
      },
      {
        title: "Tournament",
        icon: "mdi-tournament",
        path: "tournament",
      },
      {
        title: "Discover",
        icon: "mdi-magnify",
        path: "search",
      },
      {
        title: "Me",
        icon: "mdi-account",
        path: "profile",
      },
    ],
    loggedInBtnList: [
      {
        id: "notification",
        icon: "mdi-bell",
      },
      {
        id: "message",
        icon: "mdi-message-text",
      },
      // {
      //   id: "profile",
      //   icon: "mdi-account",
      // },
    ],
    navCurrent: "home",
  }),

  created() {
    const firebaseConfig = {
      apiKey: "AIzaSyDy-q4sOp_uNsy8HgTyUItbAfMGo5YaO9o",
      authDomain: "bridge-pocket-2018.firebaseapp.com",
      databaseURL: "https://bridge-pocket-2018.firebaseio.com",
      projectId: "bridge-pocket-2018",
      storageBucket: "bridge-pocket-2018.appspot.com",
      messagingSenderId: "680745470752",
      appId: "1:680745470752:web:caeaf21d578d8a1860e545",
      measurementId: "G-ZW9S48E2GY",
    };

    // Initialize Firebase
    const firebaseApp = initializeApp(firebaseConfig);
    db = getDatabase(firebaseApp);

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      this.loggedIn = user;
    });
  },

  methods: {
    routeView(value) {
      router.push("/" + value);
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.scrolled = top > 60;
    },
    isTournamentDashboard() {
      return this.$route.path.startsWith("/tournament/");
    },
    logout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
        })
        .catch((error) => {
          console.log(error);
          // An error happened.
        });
    },
  },
};
</script>
